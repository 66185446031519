import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import i18n from '../../common/i18n';
import { fillMessageWith } from '../../utils/functionsUtil';
import { moneyFormatter } from '../../utils/formatterUtil';
import useCountDown from '../useCountDown';

const useIncentiveCampaignController = (loanRequest, incentiveCampaign) => {
  const [message, setMessage] = useState();
  const {
    secondsLeft: timeLeft,
    start: startCountDown,
  } = useCountDown();

  const resolveIncentiveMessage = () => {
    const messageAux = [];
    if (incentiveCampaign?.hasHigerMaxAmount) {
      // INCENTIVO MONTO MAXIMO
      messageAux.push(i18n.IncentiveCampaign.maxAmount[0]);
      messageAux.push(parse(fillMessageWith(
        i18n.IncentiveCampaign.maxAmount[1],
        moneyFormatter(loanRequest.response.maxAmount),
        moneyFormatter(incentiveCampaign?.originalLoanRequestMaxAmount),
      )));
    } else if (incentiveCampaign?.hasLongerInstallments) {
      // INCENTIVO PLAZO MAXIMO
      messageAux.push(i18n.IncentiveCampaign.longerInstallments[0]);
      messageAux.push(parse(fillMessageWith(
        i18n.IncentiveCampaign.longerInstallments[1],
        loanRequest.response.maxTerm,
        incentiveCampaign?.originalLoanRequestMaxInstallments,
      )));
    }
    setMessage(messageAux);
  };

  const calculateAndInitCountDown = () => {
    const diffMilis = incentiveCampaign?.endDate - Date.now();
    const diffSeconds = Math.floor(diffMilis / 1000);
    startCountDown(diffSeconds < 0 ? 0 : diffSeconds);
  };

  const init = () => {
    resolveIncentiveMessage();
    calculateAndInitCountDown();
  };

  useEffect(init, []);

  return {
    message,
    hours: Math.floor(timeLeft / 3600),
    minutes: Math.floor((timeLeft % 3600) / 60),
    seconds: timeLeft % 60,
    timeLeft,
  };
};

export default useIncentiveCampaignController;
