import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import i18n from '../common/i18n';
import { fillMessageWith } from '../utils/functionsUtil';
import useIncentiveCampaignController from '../hooks/controllers/useIncentiveCampaignController';

const IncentiveCampaign = ({ loanRequest, incentiveCampaign }) => {
  const {
    message,
    hours,
    minutes,
    seconds,
    timeLeft,
  } = useIncentiveCampaignController(loanRequest, incentiveCampaign);
  return (
    <>
      {incentiveCampaign && message?.length > 0 && timeLeft > 0 && (
        <Grid container sx={{ marginTop: '24px', marginBottom: '32px' }}>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                backgroundColor: '#FFF9E5',
                border: '1px solid #C98F03',
                borderRadius: '8px',
                padding: '16px',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <img src="/assets/images/incentivo-icon.svg" alt="oferta" style={{ marginRight: '12px' }} />
              <Box>
                <Typography
                  align="left"
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    fontSize: '20px',
                    fontWeight: 700,
                    fontFamily: 'Poppins',
                    color: '#424242',
                  }}
                >
                  {fillMessageWith(i18n.IncentiveCampaign.title, hours, minutes, seconds)}
                </Typography>
                <Typography align="left" sx={{ fontSize: '16px' }}>
                  {message[0]}
                </Typography>
                <Typography align="left" sx={{ fontSize: '16px', marginTop: '12px' }}>
                  {message[1]}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

IncentiveCampaign.propTypes = {
  loanRequest: PropTypes.shape(),
  incentiveCampaign: PropTypes.shape(),
};

IncentiveCampaign.defaultProps = {
  loanRequest: undefined,
  incentiveCampaign: undefined,
};

export default IncentiveCampaign;
