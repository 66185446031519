import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import PropTypes, { shape } from 'prop-types';
import parse from 'html-react-parser';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Alert, Img, Link, Card, CardContent,
} from '@doit/pcnt-react-ui-library';
import EditIcon from '@mui/icons-material/Edit';
import '../assets/css/greetings.css';
import i18n from '../common/i18n';
import { routes } from '../constants/routes';
import { fillMessageWith, resolveRechargeCompanyLogo } from '../utils/functionsUtil';
import { moneyFormatterWithoutDecimals } from '../utils/formatterUtil';

const Greetings = ({ loanRequest }) => {
  const navigate = useNavigate();

  const isRecharge = () =>
    !loanRequest.response.rejected
    && (loanRequest.productToPay && loanRequest.productToPay.rechargeType);

  const isBillPayment = () =>
    !loanRequest.response.rejected
    && (loanRequest.productToPay && loanRequest.productToPay.billPaymentType);

  const isBNPL = () =>
    !loanRequest.response.rejected
    && (loanRequest.productToPay && loanRequest.productToPay.ecommerceCartType);

  const isShowcase = () =>
    !loanRequest.response.rejected
    && (loanRequest.productToPay && loanRequest.productToPay.showcaseType);

  const isLoanRequestWithProductToPay = () =>
    isRecharge() || isBillPayment() || isBNPL() || isShowcase();

  const isRenewerWithoutDualOffer = () =>
    (loanRequest.installmentsNumberSelected || !loanRequest.isOfferDual)
    && !loanRequest.response.rejected
    && (loanRequest.customerInActiveCampaign || loanRequest.response.prePaidLoan)
    && !isLoanRequestWithProductToPay();

  const isRenewerWithDualOffer = () =>
    !loanRequest.installmentsNumberSelected
    && loanRequest.isOfferDual
    && !loanRequest.response.rejected
    && (loanRequest.customerInActiveCampaign || loanRequest.response.prePaidLoan)
    && !isLoanRequestWithProductToPay();

  const isNormal = () =>
    (loanRequest.subProductId !== 56
    && !loanRequest.response.rejected
    && !loanRequest.customerInActiveCampaign
    && !loanRequest.response.prePaidLoan
    && !isLoanRequestWithProductToPay())
    || (loanRequest.subProductId !== 56
      && !loanRequest.response.rejected
      && !loanRequest.customerInActiveCampaign
      && loanRequest.isOfferDual
      && !loanRequest.response.prePaidLoan
      && loanRequest.installmentsNumberSelected
      && !isLoanRequestWithProductToPay());

  const isPayDay = () =>
    !loanRequest.isOfferDual
    && loanRequest.subProductId === 56
    && !loanRequest.response.rejected
    && !loanRequest.customerInActiveCampaign
    && !loanRequest.response.prePaidLoan
    && !isLoanRequestWithProductToPay();

  const isRevolvingLine = () =>
    loanRequest.productId === 46;

  const isIncentiveCampaign = () =>
    loanRequest.isIncentiveCampaign === true;

  const getMaxAmountWhenIsPrePaidLoan = () =>
    Math.floor((loanRequest.response.maxAmount - loanRequest.response.prePaidLoanAmount) / 100) * 100;

  const getRechargeAmount = () =>
    loanRequest.productToPay.products[0].productAttributes.IV1;

  const getRechargeCompanyLogo = () =>
    resolveRechargeCompanyLogo(loanRequest.productToPay.products[0].productAttributes.companyCode);

  const getRechargeCellphone = () =>
    loanRequest.productToPay.products[0].productAttributes.IDC;

  const getServiceAmount = () => {
    let { amount } = loanRequest.productToPay.products[0].productAttributes;
    if (!amount) {
      amount = loanRequest.productToPay.products[0].productAttributes.IV1;
    }
    return amount;
  };

  const getServiceCompanyName = () =>
    loanRequest.productToPay.products[0].productAttributes.companyName.toLowerCase();

  const resetProduct = () => {
    navigate(`${routes.OnboardingProductLoad}/${loanRequest.hashKey}`);
  };

  const resolveReturnCommerceURL = () =>
    loanRequest.productToPay.products[0].productAttributes.merchantReturnSuccessURL;

  const getMerchantId = () =>
    loanRequest.productToPay.products[0].productAttributes.merchantId;

  const getBnplOrder = () =>
    loanRequest.productToPay.products[0].productAttributes.orderID;

  const getBnplAmount = () =>
    loanRequest.productToPay.products[0].productAttributes.price;

  const getShowcaseAmount = () =>
    loanRequest.productToPay.products[0].productAttributes.productAmount;

  const getShowcaseName = () => {
    const { productAttributes } = loanRequest.productToPay.products[0];
    let name = productAttributes.commerceName;
    if (!name) {
      name = productAttributes.showcaseName;
    }
    return name;
  };

  return (
    <>
      {/* ********** RENOVACIÓN SIN OFERTA DUAL ********** */}
      {isRenewerWithoutDualOffer() && (
        <>
          {loanRequest.response.prePaidLoan ? (
            // con cancelacion
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Typography variant="h2" className="greetings-loan-title">
                {i18n.Greetings.renewerPrepaid.title}
              </Typography>
              {loanRequest.prepaidRecalculationDate && (
                <Box mt={2} mb={2}>
                  {loanRequest.response.loansToBePrePaidQuantity > 1 ? (
                    <Alert severity="warning" icon={false}>
                      <Typography className="greetings-renewer-prepaid-description">
                        {parse(i18n.Greetings.renewerPrepaid.description1)}
                      </Typography>
                    </Alert>
                  ) : (
                    <Alert severity="warning" icon={false}>
                      <Typography className="greetings-renewer-prepaid-description">
                        {parse(i18n.Greetings.renewerPrepaid.description2)}
                      </Typography>
                    </Alert>
                  )}
                </Box>
              )}
              {loanRequest.response.loansToBePrePaidQuantity > 1 ? (
                <Typography variant="h2" className="greetings-loan-subtitle">
                  {parse(fillMessageWith(
                    i18n.Greetings.renewerPrepaid.subtitle1,
                    moneyFormatterWithoutDecimals(getMaxAmountWhenIsPrePaidLoan()),
                    moneyFormatterWithoutDecimals(loanRequest.response.prePaidLoanAmount),
                  ))}
                </Typography>
              ) : (
                <Typography variant="h2" className="greetings-loan-subtitle">
                  {parse(fillMessageWith(
                    i18n.Greetings.renewerPrepaid.subtitle2,
                    moneyFormatterWithoutDecimals(getMaxAmountWhenIsPrePaidLoan()),
                    moneyFormatterWithoutDecimals(loanRequest.response.prePaidLoanAmount),
                  ))}
                </Typography>
              )}
            </Box>
          ) : (
            // sin cancelacion
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {isRevolvingLine() ? (
                <>
                  <Typography variant="h2" className="greetings-loan-title">
                    {i18n.Greetings.revolvingLine.title}
                  </Typography>
                  <Typography variant="h2" className="greetings-loan-subtitle">
                    {fillMessageWith(
                      i18n.Greetings.revolvingLine.subtitle,
                      moneyFormatterWithoutDecimals(
                        Math.floor(loanRequest.response.maxAmount / 100) * 100,
                      ),
                    )}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h2" className="greetings-loan-title">
                    {parse(fillMessageWith(i18n.Greetings.renewer.title,
                      moneyFormatterWithoutDecimals(
                        Math.floor(loanRequest.response.maxAmount / 100) * 100,
                      )))}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </>
      )}
      {/* ********** RENOVACIÓN CON OFERTA DUAL ********** */}
      {isRenewerWithDualOffer() && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="h1" className="greetings-dual-title">{i18n.Greetings.dual.title}</Typography>
          <Typography variant="h2" className="greetings-dual-subtitle">{i18n.Greetings.dual.subtitle}</Typography>
          <Typography variant="h3" className="greetings-dual-description">{i18n.Greetings.dual.description}</Typography>
        </Box>
      )}
      {/* ********** FLUJO PRESTAMOS NORMALES ********** */}
      {/* ********** FLUJO ADELANTOS NORMALES ********** */}
      {(isNormal() || isPayDay()) && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {isRevolvingLine() ? (
            <>
              <Typography variant="h2" className="greetings-loan-title">
                {i18n.Greetings.revolvingLine.title}
              </Typography>
              <Typography variant="h2" className="greetings-loan-subtitle">
                {fillMessageWith(
                  i18n.Greetings.revolvingLine.subtitle,
                  moneyFormatterWithoutDecimals(
                    Math.floor(loanRequest.response.maxAmount / 100) * 100,
                  ),
                )}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h2" className="greetings-loan-title">
                {isIncentiveCampaign() ? i18n.Greetings.incentiveCampaign.title : i18n.Greetings.loan.title}
              </Typography>
              <Typography variant="h2" className="greetings-loan-subtitle">
                {fillMessageWith(i18n.Greetings.loan.subtitle,
                  moneyFormatterWithoutDecimals(
                    Math.floor(loanRequest.response.maxAmount / 100) * 100,
                  ))}
              </Typography>
            </>
          )}

        </Box>
      )}
      {/* ********** FLUJO RECARGA CELULARES ********** */}
      {isRecharge() && (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Typography variant="h2" className="greetings-recharge-title">
            {parse(fillMessageWith(
              i18n.Greetings.recharge.title,
              moneyFormatterWithoutDecimals(getRechargeAmount()),
            ))}
          </Typography>
          <Box className="greetings-recharge-container">
            <Img className="greetings-recharge-logo" src={getRechargeCompanyLogo()} />
            <Typography variant="h2" className="greetings-recharge-phone">{getRechargeCellphone()}</Typography>
            {!loanRequest.installmentsNumberSelected && (
              <Link href={`${routes.OnboardingProductLoad}/${loanRequest.hashKey}`} className="greetings-recharge-edit">
                {i18n.Greetings.recharge.edit}
              </Link>
            )}
          </Box>
        </Box>
      )}
      {/* ********** FLUJO BILL PAYMENT ********** */}
      {isBillPayment() && (
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h3" className="greetings-service-title">
            {parse(fillMessageWith(
              i18n.Greetings.services.title,
              moneyFormatterWithoutDecimals(getServiceAmount()),
              getServiceCompanyName(),
            ))}
            {!loanRequest.installmentsNumberSelected && (
              <IconButton color="primary" onClick={resetProduct}>
                <EditIcon />
              </IconButton>
            )}
          </Typography>
        </Box>
      )}
      {/* ********** FLUJO CARRITO DE COMPRA ********** */}
      {isBNPL() && (
        <Box>
          <Link
            className="greetings-bnpl-return"
            href={resolveReturnCommerceURL()}
            startIcon={<ArrowBackIcon className="greetings-bnpl-return-icon" />}
          >
            {i18n.Greetings.bnpl.return}
          </Link>
          <Box mt={2} />
          <Card>
            <CardContent>
              <Box>
                <Typography className="greetings-bnpl-title">
                  {parse(fillMessageWith(i18n.Greetings.bnpl.title, moneyFormatterWithoutDecimals(getBnplAmount())))}
                </Typography>
                <Img
                  className="greetings-bnpl-commerce-logo"
                  src={`https://cc-ecommerce-assets.s3.sa-east-1.amazonaws.com/${getMerchantId()}-logo.png`}
                />
                <Typography>
                  {fillMessageWith(i18n.Greetings.bnpl.orderNumber, getBnplOrder())}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
      {/* ********** FLUJO SHOWCASE ********** */}
      {isShowcase() && (
        <Box>
          <Typography className="greetings-showcase-title">
            {parse(fillMessageWith(
              i18n.Greetings.showcase.title,
              moneyFormatterWithoutDecimals(getShowcaseAmount()),
              getShowcaseName(),
            ))}
          </Typography>
        </Box>
      )}
    </>
  );
};

Greetings.propTypes = {
  loanRequest: PropTypes.shape({
    hashKey: PropTypes.string.isRequired,
    installmentsNumberSelected: PropTypes.number,
    isOfferDual: PropTypes.bool.isRequired,
    isIncentiveCampaign: PropTypes.bool,
    response: PropTypes.shape({
      rejected: PropTypes.bool.isRequired,
      prePaidLoan: PropTypes.bool.isRequired,
      maxAmount: PropTypes.number.isRequired,
      loansToBePrePaidQuantity: PropTypes.number,
      prePaidLoanAmount: PropTypes.number,
    }).isRequired,
    customerInActiveCampaign: PropTypes.bool.isRequired,
    productToPay: PropTypes.shape({
      rechargeType: PropTypes.bool,
      billPaymentType: PropTypes.bool,
      ecommerceCartType: PropTypes.bool,
      showcaseType: PropTypes.bool,
      products: PropTypes.arrayOf(shape({})),
    }),
    productId: PropTypes.number.isRequired,
    subProductId: PropTypes.number.isRequired,
    prepaidRecalculationDate: PropTypes.string,
  }).isRequired,
};

export default Greetings;
